var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticClass: "header-nav header-nav--city",
      attrs: { role: "navigation", "aria-label": "Main" }
    },
    [
      _c("div", { staticClass: "left-group" }, [
        _vm._m(0),
        _c(
          "div",
          [
            _c(
              "transition",
              { attrs: { name: "fade-transition" } },
              [
                _c(
                  "v-btn",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !!_vm.$store.state.city.previousNavigators.length ||
                          _vm.isNavigatorRoute,
                        expression:
                          "\n            !!$store.state.city.previousNavigators.length || isNavigatorRoute\n          "
                      }
                    ],
                    staticClass: "ml-2 primary--text back-btn",
                    attrs: {
                      rounded: "",
                      "x-large": "",
                      color: "white",
                      elevation: "6"
                    },
                    on: { click: _vm.handleBackToCityClick }
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v(" mdi-arrow-left ")
                    ]),
                    _vm._v(
                      " " + _vm._s(_vm.DCSLanguageService.translate("BackTo"))
                    ),
                    _c("span", { staticClass: "teal--text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$store.state.city.previousNavigators.length > 0
                              ? _vm.$store.state.city.previousNavigators[0]
                                  .labelText
                              : _vm.DCSLanguageService.translate("City")
                          )
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "button-group-right" },
        [
          _c(
            "div",
            {
              class: !_vm.innovationsSelected
                ? "switch-container"
                : "switch-container-on"
            },
            [
              _c(
                "div",
                {
                  class: !_vm.innovationsSelected
                    ? "label-container"
                    : "label-container-on"
                },
                [
                  _c(
                    "v-icon",
                    {
                      class: !_vm.innovationsSelected
                        ? "pr-3 no-transition"
                        : "pl-3 no-transition"
                    },
                    [_vm._v(" mdi-lightbulb ")]
                  ),
                  _vm.innovationsSelected
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "innovation-see-all-button",
                          attrs: { rounded: "", small: "", text: "" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleSeeAllInnovationsClick()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.DCSLanguageService.translate("SeeAll")
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(
                    " " +
                      _vm._s(_vm.DCSLanguageService.translate("Innovations")) +
                      " "
                  ),
                  _c("v-switch", {
                    staticClass: "innovation-switch",
                    attrs: { inset: "", color: "white" },
                    model: {
                      value: _vm.innovationsSelected,
                      callback: function($$v) {
                        _vm.innovationsSelected = $$v
                      },
                      expression: "innovationsSelected"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c("HeaderLinkMenuComponent")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "https://www.dow.com" } }, [
      _c("img", {
        staticClass: "logo",
        attrs: {
          src: require("@/assets/logos/dow-diamond.svg"),
          "aria-label": "Go to home page"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }