import { AxiosResponse } from "axios";
import { combineLatest, defer, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { http } from "../http-client";
import { ProductDetail } from "@/types";
import { urlReplaceSpacesAndLower } from "@/util/url-formatter.utility";
import { serializeStrapiResponse } from "@/util/seralize.utility";

import { DCSLanguageService } from "@/services";

export class ProductService {
  private DCSLanguageService = new DCSLanguageService();

  public getProductById(id: number): Observable<ProductDetail> {
    return defer(() => http.get(`/product-details/${id}`)).pipe(
      map((res: AxiosResponse<unknown>) => res.data),
      map((product) => serializeStrapiResponse(product) as ProductDetail)
    );
  }

  public getProductListDataByIds(
    idList: number[]
  ): Observable<ProductDetail[]> {
    if (!idList) {
      return of([]);
    }

    const productRequests = idList.map((id) => this.getProductById(id));
    return combineLatest(productRequests);
  }

  public getProductListDataByInnovation(): Observable<ProductDetail[]> {
    const languageID = this.DCSLanguageService.getDCSLanguage().identifier;
    return defer(() =>
      http.get(`/dcs-language/product-details/${languageID}`)
    ).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map(
        (products) =>
          products.map((product) =>
            serializeStrapiResponse(product)
          ) as ProductDetail[]
      ),
      map((products: ProductDetail[]) => {
        products = products.filter((product) => product.innovation);
        //filter out duplicates between navigators
        const uniqueProducts = [
          ...new Map(
            products.map((product) => [product["heading"], product])
          ).values(),
        ];
        return uniqueProducts;
      })
    );
  }

  public getProductListDataByNewProduct(): Observable<ProductDetail[]> {
    const languageID = this.DCSLanguageService.getDCSLanguage().identifier;
    return defer(() =>
      http.get(`/product-details`)
    ).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map(
        (products) =>
          products.map((product) =>
            serializeStrapiResponse(product)
          ) as ProductDetail[]
      ),
      map((products: ProductDetail[]) => {
        products = products.filter((product) => product.newProduct);
        products = products.sort((a,b)=>{
          if (a.carouselOrder === undefined || a.carouselOrder === null) return 1;
          if (b.carouselOrder === undefined || b.carouselOrder === null) return -1;
          return a.carouselOrder - b.carouselOrder;
          });
        //filter out duplicates between navigators
        const uniqueProducts = [
          ...new Map(
            products.map((product) => [product["heading"], product])
          ).values(),
        ];
        return uniqueProducts;
      })
    )
  }

  public getProductDataByName(
    urlSafeName: string
  ): Observable<ProductDetail | undefined> {
    const languageID = this.DCSLanguageService.getDCSLanguage().identifier;
    return defer(() =>
      http.get(`/dcs-language/product-details/${languageID}`)
    ).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map((products) =>
        products.map(
          (product) => serializeStrapiResponse(product) as ProductDetail
        )
      ),
      map((products: ProductDetail[]) => {
        const single = products.find((product) => {
          const urlSafeListItemName = urlReplaceSpacesAndLower(product.heading);
          return urlSafeName.includes(urlSafeListItemName);
        });

        return single;
      })
    );
  }
}
