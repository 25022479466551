/* eslint-disable */

export const snakeCaseToCamelCase = (str: string): string => str.toLowerCase().replace(/([-_][a-z])/g, group =>
  group
    .toUpperCase()
    .replace('-', '')
    .replace('_', '')
);

/**
 * This method is needed because for Strapi (this applications content api) snake case is the property specification,
 * but for Javascript & Typescript projects camel casing is the recommended style guide, so this is a method that will
 * convert a snake case property to a camel case.
 * 
 * E.g. { sub_category: 1 } becomes { subCategory: 1 }
 * 
 * @param model The object that has snake case properties that need to become camel case
 * @returns The original object with all snake case properties now being camel case
 */
export const serializeStrapiResponse = (model: any): any => {
  if (!model) {
    return model;
  }

  if (Array.isArray(model)) {
    return model.map(item => serializeStrapiResponse(item));
  }

  if (typeof model === 'object' && model !== null) {
    const convertedObject: any = {};

    for (const property in model) {
      const convertedProperty = snakeCaseToCamelCase(property);
      convertedObject[convertedProperty] = serializeStrapiResponse(model[property]);
    }

    return convertedObject;
  }

  return model;
}