import { AxiosResponse } from "axios";
import { defer, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { http } from "./http-client";
import { serializeStrapiResponse } from "@/util/seralize.utility";

import { DCSLanguage, DCSLanguageIdentifiers } from "@/types";

import { LocaleService } from "./locale.service";

export class DCSLanguageService {
  private localeService = new LocaleService();

  private TRANSLATIONDICTIONARY = {
    English: {
      // Header
      Innovation: "Innovation",
      Innovations: "Innovations",
      // Space is there in Strapi, so it must be here as well.
      "Europe ": "Europe",
      "North America ": "North America",
      "Asia ": "Asia",
      "Latin America ": "Latin America",
      "India ": "India",
      "Middle East ": "Middle East",
      English: "English",
      Spanish: "Spanish",
      Portuguese: "Portuguese",
      Webinars: "Webinars",
      WebinarsAndVideos: "Webinars & Video",
      Connect: "Connect",
      MoreResources: "More Resources",
      // Contact form content ----------------------------------------------
      //    Page 1
      ConnectTitle: "Connect with a Dow specialist",
      ConnectEmail: "Let's get started with your work email address",
      WorkEmail: "Work Email",
      DataPandP: "Data Privacy and Protection",
      DataPrivacyContent:
        "The information collected in this form will only be used to ensure that we provide you with an appropriate response based upon the information you provide in this form. We will contact you only if we need additional information to appropriately answer your question or in order to address your need. The contact information you provide, including e-mail address, may be used to respond to your request. We will not use the contact information you provide in this form for product or promotional advertising unless you specifically request to receive product news or information. For more information, please review our Privacy Policy.",
      DataPrivacyInfo: "For more information, please review our",
      PrivacyPolicy: "Privacy Policy",
      //    Page 2
      FirstName: "First Name",
      LastName: "Last Name",
      Company: "Company",
      Country: "Country",
      StateProvince: "State/Province",
      PostalCode: "Postal Code",
      WhatMarket: "What market segment(s) are you interested in?*",
      SelectOptions: "Please select option(s)",
      SelectAnOption: "Please select an option",
      WhatReason: "What is your reason for contacting us?*",
      AnyComments: "Any other thoughts or comments you'd like to share?",
      Comment: "Comment",
      NeedSpecificProject: "Need specific project support?",
      //    Page 3
      WhatRole: "What is your role or job function?",
      WhatProjectSize: "What is your project size?",
      WhatBudgetType: "What is your budget type?",
      WherePurchaseProcess: "Where are you in your purchase process?",
      //    Page 4
      InterestedInMore: "Interested in hearing more from us?",
      OptInEmail: "Opt in to email for News & Events from Dow",
      //    Page 5 (Complete)
      WeLookForward: "We look forward to getting together",
      WellReachOut: "We'll be reaching out to you shortly",
      LetsExplore: "Let's get back to exploring",
      // ----------------------------------------------
      // Mobile Page Content ----------------------------------------------
      //    Region and Language Drawer
      WeReShowing: "We're showing you products applicable to your region",
      ChangeLocation: "Change your location",
      ChangeLocale: "Change your locale",
      //    Intro
      InspireTheFuturescape: "Inspire the Futurescape",
      ExploreProducts: "Explore our products:",
      BrowseDowsil:
        "Browse DOWSIL™ solutions and innovations designed to help you build beautifully and reduce your environmental footprint.",
      LearnMore: "Learn more from us:",
      TechnicalInformation:
        "Technical information and webinars to help you make the right selection and get the most out of each solution you choose.",
      WithDow: "with Dow experts.",
      SelectRegion: "Select your region",
      CurrentlyShowing: "Currently showing products for",
      TourTheCity: "Tour the City",
      //    Content
      Applications: "Applications",
      BrowseThrough:
        "Browse through our product offerings and explore how our technology is used within real-world applications.",
      YouCanAlsoView:
        "You can also view all of our latest innovations in one single view.",
      SeeAllInnovations: "See All Innovations",
      WeCelebrate:
        "We celebrate the 50th anniversary of Dow’s pioneering 4-sided silicone structural glazing, which enabled the many visually stunning glass façades seen in cities today.",
      // Footer Content ----------------------------------------------
      ExploreBuilding: "Explore Building, Construction, and Infrastructure",
      Visit: "Visit",
      ContactUs: "Contact Us",
      PrivacyStatement: "Privacy Statement",
      TermsOfUse: "Terms of Use",
      Copyright: "Copyright",
      AllRights: "All Rights Reserved.",
      Trademark: "Trademark of Dow or an affiliate company of Dow",
      // ----------------------------------------------
      // Landing Page Content -------------------------
      ExploreCityscape: "Explore the Cityscape",
      WhatsNew: "What's New",
      BuildWith: "Build your dreams with confidence.",
      ExploreOur: "Explore our DOWSIL™ Solutions and innovations designed to help you build beautifully and reduce your environmental footprint",
      LetUs: "Let us be your trusted construction partner.",
      UpcomingEvents: "Upcoming Events",
      RegisterNow: "Register Now",
      NewProducts: "New Products",
      News: "News",
      // Misc.
      EventTitle: "Join Us at Upcoming Events",
      NewProductTitle: "Explore Our Newest Products",
      NewsTitle: "News",
      NoEvents:"No Upcoming Events",
      NoWebinars:"No Webinars Available",
      NoNewProducts: "No New Products Available",
      NoNews:"No News",
      
      In: "in",
      Help: "Help",
      ExploreThe: "Explore the",
      City: "City",
      BackTo: "Back to",
      SeeAll: "See all",
      Next: "Next",
      Done: "Done",
      Submit: "Submit",
      // Product Cards
      ExploreMore: "Explore More",
      New: "NEW",
      ApplicationGuide: "Application Guide",
      ProductGuide: "Product Guide",
      Share: "Share",
      CopyLink: "Copy Link",
      ReadOn: "Read on",
      ReadLess: "Read less",
      FindDistributor: "Find Distributor",
    },
    Spanish: {
      // Header
      Innovation: "Innovacion",
      Innovations: "Innovaciones",
      // Space is there in Strapi, so it must be here as well
      "Europe ": "Europa",
      "North America ": "Norte América",
      "Asia ": "Asia",
      "Latin America ": "América Latina",
      "India ": "India",
      "Middle East ": "Oriente Medio",
      English: "English",
      Spanish: "Spanish",
      Portuguese: "Portuguese",
      Webinars: "Webinars",
      WebinarsAndVideos: "Webinars y Videos",
      Connect: "Contacto",
      MoreResources: "Más recursos",
      // Contact form content ----------------------------------------------
      //    Page 1
      ConnectTitle: "Póngase en contacto con un experto de Dow",
      ConnectEmail:
        "Comencemos con su dirección de correo electrónico profesional",
      WorkEmail: "Correo electrónico profesional",
      DataPandP: "Privacidad y protección de datos",
      DataPrivacyContent:
        "La información recopilada en este formulario solo se utilizará para asegurar que le proporcionemos una respuesta adecuada basada en la información que usted proporciona en este formulario. Nos pondremos en contacto solo si necesitamos información adicional para responder adecuadamente a su pregunta o para abordar su necesidad. Puede que se utilice la información de contacto que nos proporcione, incluida la dirección de correo electrónico, para responder a su solicitud. No utilizaremos la información de contacto que proporcione en este formulario para publicidad de productos o promociones a menos que solicite específicamente recibir noticias o información sobre productos. Para obtener más información, consulte nuestra Política de Privacidad.",
      DataPrivacyInfo:
        "Para obtener más información, consulte nuestra",
      PrivacyPolicy: "Política de Privacidad",
      //    Page 2
      FirstName: "First Name", // NEED TRANSLATION
      LastName: "Last Name", // NEED TRANSLATION
      Company: "Company", // NEED TRANSLATION
      Country: "País",
      StateProvince: "Estado/Provincia",
      PostalCode: "Código Postal",
      WhatMarket: "What market segment(s) are you interested in?*", // NEED TRANSLATION
      SelectOptions: "Please select option(s)", // NEED TRANSLATION
      SelectAnOption: "Please select an option", // NEED TRANSLATION
      WhatReason: "What is your reason for contacting us?*", // NEED TRANSLATION
      AnyComments: "Any other thoughts or comments you'd like to share?", // NEED TRANSLATION
      Comment: "Comment", // NEED TRANSLATION
      NeedSpecificProject: "Need specific project support?", // NEED TRANSLATION
      //    Page 3
      WhatRole: "What is your role or job function?", // NEED TRANSLATION
      WhatProjectSize: "What is your project size?", // NEED TRANSLATION
      WhatBudgetType: "What is your budget type?", // NEED TRANSLATION
      WherePurchaseProcess: "Where are you in your purchase process?", // NEED TRANSLATION
      //    Page 4
      InterestedInMore: "Interested in hearing more from us?", // NEED TRANSLATION
      OptInEmail: "Opt in to email for News & Events from Dow", // NEED TRANSLATION
      //    Page 5 (Complete)
      WeLookForward: "We look forward to getting together", // NEED TRANSLATION
      WellReachOut: "We'll be reaching out to you shortly", // NEED TRANSLATION
      LetsExplore: "Let's get back to exploring", // NEED TRANSLATION
      // ----------------------------------------------
      // Mobile Page Content ----------------------------------------------
      //    Region and Language Drawer
      WeReShowing: "We're showing you products applicable to your region", // NEED TRANSLATION
      ChangeLocation: "Change your location", // NEED TRANSLATION
      ChangeLocale: "Change your locale", // NEED TRANSLATION
      //    Intro
      InspireTheFuturescape: "Inspirando el paisaje del futuro ",
      ExploreProducts: "Conozca nuestros productos:",
      BrowseDowsil:
        "Conozca las soluciones e innovaciones de DOWSIL™ diseñadas para ayudarle a construir de forma atractiva y reducir su huella.",
      LearnMore: "Obtenga más información:",
      TechnicalInformation:
        "Consulte la información técnica y los webinars que le ayudarán a realizar la selección correcta y sacar el máximo partido de cada solución que elija.",
      WithDow: "with Dow experts.", // NEED TRANSLATION
      SelectRegion: "Select your region", // NEED TRANSLATION
      CurrentlyShowing: "Currently showing products for", // NEED TRANSLATION
      TourTheCity: "Explorar la Ciudad",
      //    Content
      Applications: "Applications", // NEED TRANSLATIONS
      // NEED TRANSLATION
      BrowseThrough:
        "Browse through our product offerings and explore how our technology is used within real-world applications.",
      // NEED TRANSLATION
      YouCanAlsoView:
        "You can also view all of our latest innovations in one single view.",
      SeeAllInnovations: "See All Innovations",
      WeCelebrate:
        "Celebramos el 50° aniversario del innovador acristalamiento con silicona esctructural en 4 lados, que habilitó muchas de las.",
      // Footer Content ----------------------------------------------
      ExploreBuilding: "Explore Building, Construction, and Infrastructure",
      Visit: "Visit",
      ContactUs: "Contáctenos",
      PrivacyStatement: "Politica de Privacidad",
      TermsOfUse: "Términos de Uso",
      Copyright: "Copyright",
      AllRights: "Todos los derechos reservados.",
      Trademark: "Marca comercial de Dow o de una empresa afiliada de Dow",
      // ----------------------------------------------
      // Landing Page Content -------------------------
      ExploreCityscape: "Explore la ciudad",
      WhatsNew: "Novedades",
      BuildWith: "Construya sus sueños con confianza.",
      ExploreOur: "Explore nuestras soluciones e innovaciones DOWSIL™ diseñadas para ayudarle a construir de manera hermosa y reducir su huella ambiental",
      LetUs: "Podemos ser su socio de construcción de confianza.",
      UpcomingEvents: "Próximos Eventos",
      RegisterNow: "Inscripción",
      NewProducts: "Nuevos Productos",
      News: "Noticias",
      // Misc.
      EventTitle: "Únase a Nosotros en Próximos Eventos",
      NewProductTitle: "Explore Nuestras Novedades en Productos",
      NewsTitle: "Noticias",
      NoEvents:"No Hay Eventos Próximos",
      NoWebinars:"No Hay Webinars Disponibles",
      NoNewProducts: "No Hay Nuevos Productos Disponibles",
      NoNews:"No Hay Noticias",
      In: "in",
      Help: "Ayuda",
      ExploreThe: "Explore la",
      City: "Ciudad",
      BackTo: "Volver a",
      SeeAll: "Ver Tudo",
      Next: "Siguiente",
      Done: "Listo",
      // Product Cards
      ExploreMore: "Explora Más",
      New: "NUEVO",
      ApplicationGuide: "Guia de Aplicación",
      ProductGuide: "Guía de Producto",
      Share: "Compartir",
      CopyLink: "Copiar Enlace",
      ReadOn: 'Leer más',
      ReadLess: 'Leer menos',
      FindDistributor: "Encontrar Distribuidor",
    },
    Portuguese: {
      // Header
      Innovation: "Inovaçõe",
      Innovations: "Inovações",
      // Space is there in Strapi, so it must be here as well
      "Europe ": "Europa",
      "North America ": "América do Norte",
      "Asia ": "Ásia",
      "Latin America ": "América Latina",
      "India ": "Índia",
      "Middle East ": "Médio Oriente",
      English: "English",
      Spanish: "Spanish",
      Portuguese: "Portuguese",
      Webinars: "Webinars",
      WebinarsAndVideos: "Webinars e Videos",
      Connect: "Contato",
      MoreResources: "Mais Recursos",
      // Contact form content ----------------------------------------------
      //    Page 1
      ConnectTitle: "Entre em contato com um especialista da Dow",
      ConnectEmail: "Vamos começar com o seu e-mail profissional",
      WorkEmail: "E-mail profissional",
      DataPandP: "Privacidade e Proteção de Dados",
      DataPrivacyContent:
        "As informações coletadas neste formulário serão usadas apenas para garantir uma resposta apropriada nelas baseada. Entraremos em contato apenas se precisarmos de informações adicionais para responder adequadamente à sua pergunta ou para atender à sua necessidade. As informações de contato fornecidas, incluindo o endereço de e-mail, podem ser usadas para responder à sua solicitação. Não usaremos as informações de contato fornecidas neste formulário para propaganda de produtos ou promocionais, a menos que solicite especificamente receber notícias ou informações sobre produtos. Para mais informações, consulte nossa Política de Privacidade.",
      DataPrivacyInfo:
        "Para mais informações, consulte a nossa",
      PrivacyPolicy: "Política de Privacidade",
      //    Page 2
      FirstName: "First Name", // NEED TRANSLATION
      LastName: "Last Name", // NEED TRANSLATION
      Company: "Company", // NEED TRANSLATION
      Country: "País",
      StateProvince: "Estado/Província",
      PostalCode: "Código Postal",
      WhatMarket: "Qual segmento de mercado você está interessado?", // NEED TRANSLATION
      SelectOptions: "Please select option(s)", // NEED TRANSLATION
      SelectAnOption: "Please select an option", // NEED TRANSLATION
      WhatReason: "What is your reason for contacting us?*", // NEED TRANSLATION
      AnyComments: "Any other thoughts or comments you'd like to share?", // NEED TRANSLATION
      Comment: "Comment", // NEED TRANSLATION
      NeedSpecificProject: "Need specific project support?", // NEED TRANSLATION
      //    Page 3
      WhatRole: "What is your role or job function?", // NEED TRANSLATION
      WhatProjectSize: "What is your project size?", // NEED TRANSLATION
      WhatBudgetType: "What is your budget type?", // NEED TRANSLATION
      WherePurchaseProcess: "Where are you in your purchase process?", // NEED TRANSLATION
      //    Page 4
      InterestedInMore: "Interested in hearing more from us?", // NEED TRANSLATION
      OptInEmail: "Opt in to email for News & Events from Dow", // NEED TRANSLATION
      //    Page 5 (Complete)
      WeLookForward: "We look forward to getting together", // NEED TRANSLATION
      WellReachOut: "We'll be reaching out to you shortly", // NEED TRANSLATION
      LetsExplore: "Let's get back to exploring", // NEED TRANSLATION
      // ----------------------------------------------
      // Mobile Page Content ----------------------------------------------
      //    Region and Language Drawer
      WeReShowing: "We're showing you products applicable to your region", // NEED TRANSLATION
      ChangeLocation: "Change your location", // NEED TRANSLATION
      ChangeLocale: "Change your locale", // NEED TRANSLATION
      //    Intro
      InspireTheFuturescape: "Inspirando a paisagem do futuro ",
      ExploreProducts: "Explore os nossos produtos:",
      BrowseDowsil:
        "Pesquise as soluções e inovações DOWSIL™ projetadas para ajudá-lo a construir de maneira bonita e reduzir sua pegada ambiental.",
      LearnMore: "Mais informações:",
      TechnicalInformation:
        "Procure informações técnicas e webinars para ajudá-lo a fazer a seleção certa e tirar o máximo proveito de cada solução que escolher.",
      WithDow: "with Dow experts.", // NEED TRANSLATION
      SelectRegion: "Select your region", // NEED TRANSLATION
      CurrentlyShowing: "Currently showing products for", // NEED TRANSLATION
      TourTheCity: "Explorar la Ciudad",
      //    Content
      Applications: "Applications", // NEED TRANSLATIONS
      // NEED TRANSLATION
      BrowseThrough:
        "Browse through our product offerings and explore how our technology is used within real-world applications.",
      // NEED TRANSLATION
      YouCanAlsoView:
        "You can also view all of our latest innovations in one single view.",
      SeeAllInnovations: "ver tudo Inovações",
      WeCelebrate:
        "Celebramos el 50° aniversario del innovador acristalamiento con silicona esctructural en 4 lados, que habilitó muchas de las.",
      // Footer Content ----------------------------------------------
      ExploreBuilding: "Explore Building, Construction, and Infrastructure",
      Visit: "Visit",
      ContactUs: "Contáctenos",
      PrivacyStatement: "Politica de Privacidad",
      TermsOfUse: "Términos de Uso",
      Copyright: "Copyright",
      AllRights: "All Rights Reserved.",
      Trademark: "Marca comercial of Dow or an affiliate company of Dow",
      // ----------------------------------------------
      // Landing Page Content -------------------------
      ExploreCityscape: "Explore a cidade",
      WhatsNew: "Novidades",
      BuildWith: "Construa seus sonhos com confiança.",
      ExploreOur: "Explore nossas soluções e inovações DOWSIL™ desenvolvidas para ajudá-lo a projetar belas construções e reduzir sua pegada ambiental",
      LetUs: "Podemos ser seu parceiro de construção de confiança.",
      UpcomingEvents: "Próximos eventos",
      RegisterNow: "Inscrição",
      NewProducts: "Novos produtos",
      News: "Notícias",
      // Misc.
      EventTitle: "Junte-se a nós nos próximos eventos",
      NewProductTitle: "Explore nossas novidades em produtos",
      NewsTitle: "Notícias",
      NoEvents:"Sem eventos futuros",
      NoWebinars:"Nenhum Webinar Disponível",
      NoNewProducts: "Nenhum Novo Produto Disponível",
      NoNews:"Não há notícias",
      In: "in",
      Help: "Ajuda",
      ExploreThe: "Explore la",
      City: "Cidade",
      BackTo: "Voltar à",
      SeeAll: "Ver Tudo",
      Next: "Siguiente",
      Done: "Listo",
      // Product Cards
      ExploreMore: "Explore Mais",
      New: "NOVO",
      ApplicationGuide: "Guia de Aplicação",
      ProductGuide: "Guia do Produto",
      Share: "Compartilhar",
      CopyLink: "Copiar Link",
      ReadOn: 'Leia mais',
      ReadLess: 'Leia menos',
      FindDistributor: "Encontrar Distribuidor",
    },
  };

  public getDCSLanguages(): Observable<DCSLanguage[]> {
    return defer(() => http.get("dcs-language/languages")).pipe(
      map((res: AxiosResponse<unknown[]>) => res.data),
      map((languages) =>
        languages.map(
          (language) => serializeStrapiResponse(language) as DCSLanguage
        )
      )
    );
  }

  private initializeDCSLanguageStorage(): void {
    const currentLocale = this.localeService.getLocale();
    switch (currentLocale.code) {
      case "en":
        this.setDCSLanguage({
          identifier: DCSLanguageIdentifiers.europeEnglish,
          name: "English",
        });
        break;
      case "en-US":
        this.setDCSLanguage({
          identifier: DCSLanguageIdentifiers.northAmericaEnglish,
          name: "English",
        });
        break;
      case "en-BZ":
        this.setDCSLanguage({
          identifier: DCSLanguageIdentifiers.latinAmericaEnglish,
          name: "English",
        });
        break;
      case "en-IN":
        this.setDCSLanguage({
          identifier: DCSLanguageIdentifiers.indiaEnglish,
          name: "English",
        });
        break;
      case "en-AU":
        this.setDCSLanguage({
          identifier: DCSLanguageIdentifiers.asiaEnglish,
          name: "English",
        });
        break;
      case "en-SA":
        this.setDCSLanguage({
          identifier: DCSLanguageIdentifiers.middleEastEnglish,
          name: "English",
        });
        break;
    }
  }

  public getDCSLanguage(): DCSLanguage {
    try {
      const language = localStorage.getItem("DCSLanguage");
      if (language === null) {
        this.initializeDCSLanguageStorage();
      }
      const languageID = JSON.parse(
        localStorage.getItem("DCSLanguage") as string
      ) as DCSLanguage;
      return languageID;
    } catch (e) {
      throw new Error("UNABLE TO GET LANGUAGE");
    }
  }

  public setDCSLanguage(language: DCSLanguage): void {
    localStorage.setItem("DCSLanguage", JSON.stringify(language));
  }

  private getTranslation(language: string, identifier: string): string {
    if (this.TRANSLATIONDICTIONARY[language][identifier]) {
      return this.TRANSLATIONDICTIONARY[language][identifier];
    } else {
      return "ID: " + identifier + " !!! ERROR: PLEASE ADD TRANSLATION !!!";
    }
  }

  public translate(wordIdentifier: string): string {
    const currentLanguage = this.getDCSLanguage().identifier;
    if (currentLanguage.includes("English")) {
      return this.getTranslation("English", wordIdentifier);
    } else if (currentLanguage.includes("Spanish")) {
      return this.getTranslation("Spanish", wordIdentifier);
    } else if (currentLanguage.includes("Portuguese")) {
      return this.getTranslation("Portuguese", wordIdentifier);
    } else {
      return "!!! ERROR: PLEASE ADD LANGUAGE !!!";
    }
  }
}
